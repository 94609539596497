header {
  .swiper-slide {
    background: rgba(0, 0, 0, 0.55);
    @apply relative  mx-auto w-full flex items-center justify-start relative z-10;
    &:after {
      position: absolute;
      top: 0;
      content: "";
      background: rgba(#191930, 0.85);
      width: 100%;
      height: 100%;
      z-index: 10;
    }
  }
  .slider-image {
    @apply absolute object-center object-cover w-full h-full z-0;
  }
  .navigation-arrow {
    @apply z-10 absolute opacity-75 w-12 h-12 p-3;
    @apply transform -translate-y-1/2 transition-all duration-700;
    @apply rounded-md;
    top: 50%;
    border: 2px solid rgba(255, 255, 255, 0.2);
    &:hover {
      @apply border-transparent shadow-2xl opacity-100;
      background: linear-gradient(to right, #f61b10, #ef0963);
    }
    &.arrow--left {
      left: 1vw;
      &:hover {
        left: 0.5vw;
      }
    }
    &.arrow--right {
      right: 1vw;
      &:hover {
        right: 0.5vw;
      }
    }
  }
}

@media (min-width: 1920px) {
  .swiper-slide {
    height: 800px;
  }
}

@media (min-width: 1600px) and (max-width: 1920px) {
  .swiper-slide {
    height: 750px;
  }
}

@media (min-width: 1360px) and (max-width: 1600px) {
  .swiper-slide {
    height: 650px;
  }
}

@media (min-width: 1024px) and (max-width: 1360px) {
  .swiper-slide {
    height: 600px;
  }
}

@media (max-width: 1024px) {
  .swiper-slide {
    height: 450px;
  }
}
