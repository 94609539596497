.map{
  overflow:hidden;
  padding-bottom:30.25%;
  position:relative;
  max-height: 280px;
  height:0;
  iframe{
    max-height: 280px;
    left:0;
    top:0;
    height:100%;
    width:100%;
    position:absolute;
  }
}