@import url("https://fonts.googleapis.com/css?family=Montserrat:200,300,400,500,600,700,800,900&display=swap");

@import "./vendor/_tailwind.css";
/* purgecss start ignore */
@import "./vendor/swiper";
@import "./vendor/animate";
@import "./vendor/aos";
/* purgecss end ignore */



@import "./header";
@import "./button";
@import "./maps";

.bg-gradient {
  background: linear-gradient(to right, #93291e, #ed213a);
}

@media (min-width: 1023px) and (max-width: 1200px) {
  .container {
    padding-left: 25px;
    padding-right: 25px;
  }
}